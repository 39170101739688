export function isServiceDestoryedError(error) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return error instanceof ServiceDestoryedError;
}
export class ServiceDestoryedError extends Error {
    Service;
    service = null;
    constructor(Service, message) {
        super(message || '');
        this.Service = Service;
        this.service = Service;
        console.info('[Service has been destroyed]: ', Service);
    }
}
export default class DestoryedStateService {
    destoryed$ = false;
    init$() {
        this.destoryed$ = false;
    }
    destroy$() {
        this.destoryed$ = true;
    }
    isDestoryed$() {
        if (this.destoryed$) {
            throw new ServiceDestoryedError(this);
        }
    }
}
