export function create() {
    /**
     * reference: https://github.com/moll/js-promise-defer/blob/master/index.js
     */
    const deferred = Object.create({});
    deferred.promise = new Promise(function (resolve, reject) {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });
    return deferred;
}
