export class SystemService {
    environment;
    constructor(environment) {
        this.environment = environment;
    }
    get Debug() {
        return this.environment.Debug || this.environment.Development;
    }
    get Verbose() {
        return this.environment.Verbose;
    }
    get Region() {
        return this.environment.Region;
    }
    redirectTo(url) {
        console.log('Begin Redirect To', url);
        if (this.environment.NoRedirect) {
            return;
        }
        window.location.href = url;
    }
    reportError(...err) {
        if (this.Debug) {
            console.error(...err);
        }
    }
    reportVerboseError(...err) {
        if (this.Verbose) {
            console.error(...err);
        }
    }
}
