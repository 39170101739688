/**
 * 延迟 resolve 数据
 * @param data 数据
 * @param delay 延迟时间（单位：毫秒）, 默认值 3000
 * @returns
 */
export function resolve(data, delay = 1000) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(data);
        }, delay);
    });
}
export class Lazy {
    fn;
    _hasCache = false;
    _cacheValue;
    constructor(fn) {
        this.fn = fn;
    }
    get Value() {
        if (!this._hasCache) {
            this._hasCache = true;
            this._cacheValue = this.fn();
        }
        return this._cacheValue;
    }
    static from(fn) {
        return new Lazy(fn);
    }
    static of(value) {
        return new Lazy(() => value);
    }
}
/**
 * Accessor Singleton Decorators
 */
export function singleton() {
    return function (target, _propertyKey, descriptor) {
        const get = descriptor.get;
        let result;
        let hasCache = false;
        descriptor.get = function () {
            if (!hasCache) {
                result = get.call(target);
                hasCache = true;
            }
            return result;
        };
    };
}
export function batchProxy(fn, delay = 1000) {
    const cache = [];
    let timer;
    let promise;
    return (codes) => {
        cache.push(...codes);
        if (timer) {
            return promise;
        }
        promise = new Promise((resolve, reject) => {
            timer = window.setTimeout(() => {
                fn(cache)
                    .then(resolve)
                    .catch(reject);
                clearTimeout(timer);
                timer = null;
                cache.length = 0;
            }, delay);
        });
        return promise;
    };
}
/**
 * 读取文件数据
 * @param blob
 * @returns
 */
export function readAsDataURL(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', () => {
            resolve(reader.result);
        });
        reader.addEventListener('error', () => {
            reject(reader.error);
        });
        reader.addEventListener('abort', () => {
            reject(reader.error);
        });
        reader.readAsDataURL(blob);
    });
}
/**
 * 下载文件
 * @param src 下载链接
 */
export function createIframeDownload(src) {
    if (!src)
        return;
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.setAttribute('src', src);
    document.body.appendChild(iframe);
    /**
     * Firefox 会触发 load 事件，但是如果此时删除 iframe，文件将不会被下载
     * Chrome 不会触发 load 事件
     * 由于以上情况，现在改为 1 分钟以后删除 iframe
     */
    setTimeout(() => {
        document.body.removeChild(iframe);
        iframe.remove();
    }, 60 * 1000);
}
