import { create } from './deferred';
import { create as _create } from '@analytics/patsnap-copilot/src/deferred';
import { CommonApis } from '@eureka/services/src/apis/common.api';
export var EurekaProduct;
(function (EurekaProduct) {
    /**
     * Eureka
     */
    EurekaProduct["GENERAL"] = "GENERAL";
    /**
     * 材料
     */
    EurekaProduct["MATERIAL"] = "MATERIAL";
})(EurekaProduct || (EurekaProduct = {}));
export var EurekaSelectedModule;
(function (EurekaSelectedModule) {
    EurekaSelectedModule["STANDARD"] = "STANDARD";
    EurekaSelectedModule["AIR"] = "AIR";
})(EurekaSelectedModule || (EurekaSelectedModule = {}));
export var EUploadCode;
(function (EUploadCode) {
    /**
     * IP范围
     */
    EUploadCode[EUploadCode["IPForbidden"] = 90113001] = "IPForbidden";
    /**
     * 管理员
     */
    EUploadCode[EUploadCode["AdminForbidden"] = 90113002] = "AdminForbidden";
})(EUploadCode || (EUploadCode = {}));
export const controlDeferred = create();
export const patsnapCopilotUpgradeInvite = _create();
export const airControlDeferred = create();
CommonApis.getGlobalControl().then(res => {
    controlDeferred.resolve(res);
    patsnapCopilotUpgradeInvite.resolve(res.activity);
    return res;
}).then(res => {
    const { air_feature, selected_module, product } = res;
    const isAir = selected_module === EurekaSelectedModule.AIR && air_feature;
    const isMaterial = product === EurekaProduct.MATERIAL;
    const air_hightest = isAir && !isMaterial;
    airControlDeferred.resolve({
        ...res,
        air_hightest,
    });
});
