import * as qs from 'qs';
import _, { isEqual, omit, pick } from 'lodash';
import { CommonApis } from './apis/common.api';
import { isAxiosError } from './exception';
import { DeployEnv, Site, SLD } from '@patsnap-utils/biz';
import { REGION } from '@eureka/utils/environment';
/**
 * us/eu下自动赋权之后调用销售线索
 */
function postAccountInfoHubspot(isOldUser = false) {
    if ([DeployEnv.US, DeployEnv.EU].includes(REGION)) {
        return CommonApis.postAccountInfoHubspot(isOldUser);
    }
    else {
        return Promise.resolve();
    }
}
const MAX_TRIAL_STORAGE_KEY = 'ADDTRIAL_clickcount';
const MAX_TRIAL_STORAGE_COUNT = 5;
class AddTrialService {
    passport = undefined;
    ENABLE_AUTO_ADD_TRIAL = true;
    async addTrial(headerService, passport) {
        try {
            this.passport = passport;
            const grantedProductIds = headerService.account?._info.granted_product_ids;
            if (grantedProductIds === undefined) {
                throw new Error('Granted_product_ids is undefined!');
            }
            const needAddTrialData = await CommonApis.needAddTrial();
            this.ENABLE_AUTO_ADD_TRIAL = _.get(needAddTrialData, 'data', false);
            const trialCounts = sessionStorage[MAX_TRIAL_STORAGE_KEY] || 0;
            if (this.ENABLE_AUTO_ADD_TRIAL) {
                /** 后端取自动赋权的来源网站做统计信息 */
                const fromSite = this.handleUserTrialFromSite();
                const extendBody = this.getAddTrialExtendBody();
                if (trialCounts > MAX_TRIAL_STORAGE_COUNT) {
                    sessionStorage.removeItem(MAX_TRIAL_STORAGE_KEY);
                    window.location.href = new Site({ sld: SLD.EUREKA, pathname: '403/' }).href;
                    throw new Error(`Trial counts is over ${MAX_TRIAL_STORAGE_COUNT}!`);
                }
                await CommonApis.addTrial(fromSite, extendBody);
                const hubspotTask = postAccountInfoHubspot(_.size(grantedProductIds) > 0);
                this.removeUserTrialFromSite();
                localStorage.removeItem('patsnap_header_account_info');
                console.warn('[AddTrial]', '恭喜你获得试用权限啦！');
                if (!this.passport)
                    throw new Error('passport is not setup!');
                this.passport?.removeLocal();
                // /** 用户赋权的次数 */
                if (sessionStorage[MAX_TRIAL_STORAGE_KEY]) {
                    sessionStorage[MAX_TRIAL_STORAGE_KEY] = Number(sessionStorage[MAX_TRIAL_STORAGE_KEY]) + 1;
                }
                else {
                    sessionStorage[MAX_TRIAL_STORAGE_KEY] = 1;
                }
                hubspotTask.finally(() => {
                    window.location.reload();
                });
            }
        }
        catch (error) {
            console.error('[AddTrial]', error);
        }
        finally {
            this.ENABLE_AUTO_ADD_TRIAL = false;
            this.maybeRemoveParamsAndReload();
        }
    }
    handleUserTrialFromSite() {
        const userTrialFrom = localStorage.getItem('userTrialFrom');
        if (userTrialFrom) {
            return userTrialFrom;
        }
        const fromSite = document.referrer;
        localStorage.setItem('userTrialFrom', fromSite);
        return fromSite || '';
    }
    removeUserTrialFromSite() {
        localStorage.removeItem('userTrialFrom');
    }
    /**
     * 邀请注册相关信息
     */
    getAddTrialExtendBody() {
        const query = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        // warning: 直接全部给后端可能会带有多余的参数
        // @ts-ignore
        return query;
    }
    /**
     * 去掉邀请相关的url参数
     */
    removeInviteParams(query) {
        const restParams = omit(query, 'channel_source', 'activity_type', 'inviter_id', 'invitation_code');
        return qs.stringify(restParams, {
            addQueryPrefix: true,
        });
    }
    /**
     * 测试params是否相等
     */
    isEqualSearchParams(paramsA, paramsB) {
        if (paramsA === paramsB) {
            return true;
        }
        const paramAObj = qs.parse(paramsA, {
            ignoreQueryPrefix: true,
        });
        const paramBObj = qs.parse(paramsB, {
            ignoreQueryPrefix: true,
        });
        return isEqual(paramAObj, paramBObj);
    }
    /**
     * 由于header头中比较难以hack登出的url处理, 目前直接reload处理
     * hack: 需要立即删除掉参数中的邀请参数, 否则登出后,account页面还显示邀请相关
     * ref: https://patsnap.atlassian.net/browse/TOC-51095
     */
    maybeRemoveParamsAndReload() {
        const rawSearch = window.location.search;
        const extendBody = this.getAddTrialExtendBody();
        const restSearch = this.removeInviteParams(extendBody);
        if (!this.isEqualSearchParams(rawSearch, restSearch)) {
            const inviteParams = pick(extendBody, 'channel_source', 'activity_type', 'inviter_id', 'invitation_code');
            console.log('[AddTrial]', 'Remove invite params and reload!!', JSON.stringify(inviteParams));
            // 自带reload功能
            window.location.search = restSearch;
        }
    }
}
export const addTrialService = new AddTrialService();
export function errorHandleIgnore403(e) {
    return isAxiosError(e) && e.response?.status === 403;
}
