import { indexOf } from 'lodash';
import { isAsyncCancelError, isAxiosError } from './exception';
import { isRnDError } from './error';
import { isServiceDestoryedError } from './destoryed-state-service';
/**
 * 由于全局的异常处理器支持异步处理目前解决方式是先处理掉异常
 * ` event.preventDefault()
 *  event.stopImmediatePropagation()`
 * 再加入$tag, 第二次判断如果有$tag, 就不处理了, 丢给默认异常处理器或者第三方报告
 */
const $tag = Object.create(null);
function isUnhandled(e) {
    return e && e.$tag === $tag;
}
function reThrow(e) {
    e.$tag = $tag;
    setTimeout(() => {
        throw e;
    }, 0);
}
export class ErrorHandlerService {
    systemService;
    constructor(systemService) {
        this.systemService = systemService;
    }
    _handlers = [];
    start() {
        window.addEventListener('error', this.onError);
        window.addEventListener('unhandledrejection', this.onUnHandledRejection);
    }
    end() {
        window.removeEventListener('error', this.onError);
        window.removeEventListener('unhandledrejection', this.onUnHandledRejection);
    }
    addHandler(handler) {
        if (indexOf(this._handlers, handler) === -1) {
            this._handlers.push(handler);
        }
        else {
            console.error('ErrorHandlerService: handler has existed:', handler);
        }
        return () => {
            const idx = indexOf(this._handlers, handler);
            if (idx !== -1) {
                this._handlers.splice(idx, 1);
            }
        };
    }
    ignoreCommonError(err) {
        if (isAsyncCancelError(err)) {
            return true;
        }
        if (isServiceDestoryedError(err)) {
            return true;
        }
        return false;
    }
    commonError(err) {
        if (isAxiosError(err)) {
            console.warn('isAxiosError', err);
            // if (_.includes(['4', '5'], `${err.response?.status}`.substring(0, 1))) {
            //     this.errorDisplay(this.i18n.t('loginFail.unknown') as string)
            //     return true
            // }
            // if (err.request.status === 0) {
            //     this.errorDisplay(this.i18n.t('loginFail.unknown') as string)
            //     return true
            // }
        }
        if (isRnDError(err)) {
            this.errorDisplay(err);
            return true;
        }
        return false;
    }
    /**
     *调试器异常
     */
    isDevtoolsError(err) {
        if (err) {
            if (err.name === 'SyntaxError' || err.name === 'EvalError') {
                return true;
            }
        }
        return false;
    }
    async handleError(event, err) {
        const { _handlers, } = this;
        if (this.ignoreCommonError(err)) {
            this.systemService.reportVerboseError(event, err);
            return true;
        }
        this.systemService.reportError(event, err);
        for (let i = 0, len = _handlers.length; i < len; i++) {
            const handler = _handlers[i];
            try {
                const ret = await handler(err, event);
                if (ret) {
                    return true;
                }
            }
            catch (e) {
                console.error(e);
            }
        }
        if (this.commonError(err)) {
            return true;
        }
        return false;
    }
    async handleErrorWrapper(event, error) {
        // 调试器异常不处理
        if (this.isDevtoolsError(error)) {
            return;
        }
        if (!isUnhandled(error)) {
            event.preventDefault();
            event.stopImmediatePropagation();
            const handled = await this.handleError(event, error);
            if (!handled) {
                // 需要继续抛出, 给sentry之类的处理
                reThrow(error);
            }
        }
    }
    onError = (e) => {
        return this.handleErrorWrapper(e, e.error);
    };
    onUnHandledRejection = (e) => {
        return this.handleErrorWrapper(e, e.reason);
    };
    // #region Common Display
    _errorDisplayProxy = (data) => {
        console.error(data);
    };
    errorDisplay = (data) => {
        this._errorDisplayProxy(data);
    };
    setErrorDisplay(fn) {
        this._errorDisplayProxy = fn;
    }
}
