import jsCookie from 'js-cookie';
const EUREKA_LOGIN_COOKIE = 'eureka_login';
/**
 * add cookie: eureka_login
 */
export function setEurekaLoginCookie() {
    jsCookie.set(EUREKA_LOGIN_COOKIE, '1');
}
/**
 * remove cookie: eureka_login
 */
export function removeEurekaLoginCookie() {
    jsCookie.remove(EUREKA_LOGIN_COOKIE);
}
