/**
 * 顶部导航
 */
export var EHeaderMenu;
(function (EHeaderMenu) {
    EHeaderMenu["NewTechnology"] = "NewTechnology";
    EHeaderMenu["Idea"] = "Idea";
    EHeaderMenu["TechnologyRoadmap"] = "TechnologyRoadmap";
    EHeaderMenu["Workspace"] = "Workspace";
    EHeaderMenu["Monitor"] = "Monitor";
    EHeaderMenu["Empty"] = "";
    EHeaderMenu["Project"] = "Project";
    EHeaderMenu["AIDisclosure"] = "AIDisclosure";
    EHeaderMenu["Workflow"] = "Workflow";
})(EHeaderMenu || (EHeaderMenu = {}));
/**
 * 路由和顶部导航的映射关系
 */
export var ERouteMenu;
(function (ERouteMenu) {
    ERouteMenu["RESULTS"] = "";
    ERouteMenu["DISCLOSURE"] = "NewTechnology";
    ERouteMenu["IDEA"] = "Idea";
    ERouteMenu["TECHNOLOGY-ROADMAP"] = "TechnologyRoadmap";
    ERouteMenu["COMPETITOR"] = "Monitor";
    ERouteMenu["WORKSPACE"] = "Workspace";
    ERouteMenu["MONITOR"] = "Monitor";
    ERouteMenu["PROJECT"] = "Project";
    ERouteMenu["AI-DISCLOSURE"] = "AIDisclosure";
    ERouteMenu["WORKFLOW"] = "Workflow";
})(ERouteMenu || (ERouteMenu = {}));
export const HOME_PAGE = 'UNIVERSALPATENTSEARCH';
