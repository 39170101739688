import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementLocale from 'element-ui/lib/locale';
import _ from 'lodash';
import { inputUniversalLocale } from '@eureka/search-inputs/src/input-universal/locales';
import { getCurrentLanguage } from '@eureka/language-tool';
Vue.use(VueI18n);
const lang = getCurrentLanguage();
export const i18n = new VueI18n({
    locale: lang,
    fallbackLocale: 'en',
    messages: {},
});
export async function loadI18n(lang, fallbackLocale = 'en') {
    let results;
    if (lang === 'cn') {
        const { message } = await import('./cn');
        results = await Promise.all(message);
    }
    if (lang === 'en') {
        const { message } = await import('./en');
        results = await Promise.all(message);
    }
    if (lang === 'jp') {
        const { message } = await import('./jp');
        results = await Promise.all(message);
    }
    if (lang === 'tw') {
        const { message } = await import('./tw');
        results = await Promise.all(message);
    }
    let data = results.reduce((obj, { default: data }) => {
        return _.merge(obj, data);
    }, {});
    data = _.merge(data, inputUniversalLocale[lang]);
    i18n.setLocaleMessage(lang, data);
    ElementLocale.i18n((key, value) => i18n.t(key, value));
    return i18n;
}
