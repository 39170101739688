import { mergeWith, isArray, unionWith, isEqual } from 'lodash';
/**
 * log
 * @param type log类型
 * @param id logId
 * @param input 输入
 * @param pending 延时
 */
function log(id, input, pending) {
    console.group('%c%s(%s)', 'color:#0764E9;padding:2px;', '[MergeApi]', id);
    console.log('%c延时:', 'color:green;padding:2px;', `${pending} ms`);
    console.log('%c参数:', 'color:green;padding:2px;', input);
    console.groupEnd();
}
/**
 * 合并接口调用
 * @param config 配置
 * @returns
 */
export function MergeApi(config) {
    let cache = {};
    let timer = null;
    let promise = Promise.resolve();
    return function (target, propertyKey, descriptor) {
        const { delay = 500, debug = false } = config || {};
        const key = `${target.name}.${propertyKey}`;
        const method = descriptor.value;
        descriptor.value = function (arg = {}) {
            const input = JSON.parse(JSON.stringify(arg));
            mergeWith(cache, input, (obj, src) => {
                if (isArray(obj)) {
                    return unionWith(obj, src, isEqual);
                }
            });
            if (timer) {
                return promise;
            }
            promise = new Promise((resolve, reject) => {
                timer = window.setTimeout(() => {
                    debug && log(key, cache, delay);
                    method.apply(this, [cache])
                        .then(resolve)
                        .catch(reject);
                    clearTimeout(timer);
                    timer = null;
                    cache = {};
                }, delay);
            });
            return promise;
        };
        return descriptor;
    };
}
