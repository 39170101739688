import { container } from '../container';
export class ApiResults {
    // @MockApi(mockResults)
    static queryList(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/search/srp`,
            data,
        }).then(res => {
            return res.data;
        });
    }
    static queryCount(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/analysis/count`,
            data,
        }).then(res => {
            return res.data;
        });
    }
    // 图像搜索query
    static queryDesignList(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/search/srp/image`,
            data,
        }).then(res => {
            return res.data;
        });
    }
    static getFullImages(params) {
        return container.RnDHttp.get({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/patent/id/${params.patent_id}/full-images`,
            params,
        }).then(res => {
            return res.data;
        });
    }
    static pageInit() {
        return container.RnDHttp.get({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/search/srp/init`,
        }).then(res => {
            return res.data;
        });
    }
    static analysisResult(chartType, data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/analysis/chart/${chartType}`,
            data,
            cache: true,
        }).then(res => {
            return res.data;
        });
    }
    static exportExcel(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/analysis/export/excel`,
            data,
        }).then(res => {
            return res.data;
        });
    }
    static analysisTrack(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/analysis/tracking`,
            data,
        });
    }
}
