import _ from 'lodash';
class StorageService {
    name = '';
    storage;
    constructor(name, storage) {
        this.name = name;
        this.storage = storage;
    }
    get(key) {
        const item = this.storage.getItem(key);
        try {
            return JSON.parse(item);
        }
        catch (e) {
            return item;
        }
    }
    set(key, value) {
        if (_.isNil(value)) {
            console.error('Invalidate value:', value);
        }
        this.storage.setItem(key, JSON.stringify(value));
    }
    keys() {
        return _.map(_.range(this.storage.length), index => {
            return this.storage.key(index);
        });
    }
    delete(key) {
        this.storage.removeItem(key);
    }
    clear() {
        this.storage.clear();
    }
}
export const LocalStorageService = new StorageService('LocaleStorageService', localStorage);
