import { merge, noop } from 'lodash';
export class BaseHttpService {
    constructor(errorHandlerService) {
        this._errorDisplayProxy = errorHandlerService.errorDisplay;
    }
    // #region 通用处理相关
    _errorDisplayProxy = noop;
    /**
     * 用于后期显示设置, 有的错误必须依靠 vue root
     * @param fn
     */
    setErrorDisplay(fn) {
        this._errorDisplayProxy = fn;
    }
    get(config) {
        config = merge({
            method: 'get',
        }, config);
        return this.request(config);
    }
    post(config) {
        config = merge({
            method: 'post',
        }, config);
        return this.request(config);
    }
    postForm(config) {
        config = merge({
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
        }, config);
        return this.request(config);
    }
    postMultipartForm(config) {
        config = merge({
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }, config);
        return this.request(config);
    }
    put(config) {
        config = merge({
            method: 'put',
        }, config);
        return this.request(config);
    }
    patch(config) {
        config = merge({
            method: 'patch',
        }, config);
        return this.request(config);
    }
    patchForm(config) {
        config = merge({
            method: 'patch',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }, config);
        return this.request(config);
    }
    delete(config) {
        config = merge({
            method: 'delete',
        }, config);
        return this.request(config);
    }
    getPlainJson(config) {
        /* 直接XHR请求s3可能会有跨域问题，Model Federation host 本地开发中必定产生跨域问题 */
        return fetch(config.url, { mode: 'cors', credentials: 'omit' })
            .then(res => res.json());
    }
    withLoading(self, p, useLoading = true) {
        if (!useLoading) {
            return p;
        }
        self.loading = true;
        return p.finally(() => {
            self.loading = false;
        });
    }
    withFieldLoading(self, p, field) {
        self[field] = true;
        return p.finally(() => {
            self[field] = false;
        });
    }
}
