import patsnapPopupManager from '@patsnap-ui/extensions/lib/utils/popup/popup-manager';
import baseManager from 'element-ui/lib/utils/popup/popup-manager';
// import bizDialogManager from '@patsnap-biz/dialog/node_modules/element-ui/lib/utils/popup/popup-manager';
const baseZIndex = baseManager.zIndex;
const managers = [
    patsnapPopupManager
    // bizDialogManager
];
function nextZIndex() {
    const zIndex = ++baseManager.zIndex;
    managers.forEach((manager) => {
        manager.zIndex = zIndex;
    });
    return zIndex;
}
export function hackZIndex() {
    [baseManager, ...managers].forEach((manager) => {
        try {
            manager.zIndex = baseZIndex;
            manager.nextZIndex = nextZIndex;
        }
        catch (error) {
            console.log('[hack z index]: ', error);
        }
    });
}
