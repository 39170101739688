import { create } from '@eureka/utils/deferred';
import { EurekaProduct } from '@eureka/utils/control';
export const DEFAULT_INIT = {
    acl: {
        analysis_count: 0,
        advanced_search: false,
        batch_save: false,
    },
    selected_product: EurekaProduct.GENERAL,
};
export const searchInitDeferred = create();
