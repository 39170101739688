import { RequestChannel, psHttp$ } from '@patsnap/common-ps-http';
import { currentClientUUID } from '@patsnap/uniform-setting';
import { BaseHttpService } from './base-http-service';
import { includes } from 'lodash';
import { ExceptionFactory, HttpSuccessException, HttpFailException, isAxiosError, } from './exception';
import { prepareTokenDeferred } from '@eureka/utils/account';
export const RnDHttpException = ExceptionFactory('$$RnDHttpException');
/**
 * 401,403不要弹出错误码
 */
const ignoreStatus = [401, 403];
export class RnDHttp extends BaseHttpService {
    httpClient;
    errorHandlerService;
    _channelsMap = new Map();
    constructor(httpClient, errorHandlerService) {
        super(errorHandlerService);
        this.httpClient = httpClient;
        this.errorHandlerService = errorHandlerService;
        this.configErrorHandler();
    }
    configErrorHandler() {
        this.errorHandlerService.addHandler(async (error) => {
            if (HttpSuccessException.is(error)) {
                this._errorDisplayProxy({
                    code: `${error.data.error_code}`,
                    params: error.data.error_params,
                });
                return true;
            }
            if (!HttpFailException.is(error)) {
                return false;
            }
            else if (includes(ignoreStatus, error.response?.status)) {
                return true;
            }
            const httpFailExceptionData = error.response?.data;
            if (!httpFailExceptionData) {
                return false;
            }
            this._errorDisplayProxy({
                code: `${httpFailExceptionData.numeric_error_code}`,
                params: httpFailExceptionData.error_params,
                message: httpFailExceptionData.error_message,
            });
            return true;
        });
    }
    static responseSuccessHandler(res) {
        const data = res.data;
        if (data.status === false) {
            throw HttpSuccessException.mark(res);
        }
        return res.data;
    }
    static responseFailHandler(error) {
        if (!isAxiosError(error)) {
            throw error;
        }
        throw HttpFailException.mark(error);
    }
    get headers() {
        return {
            'X-Device-ID': currentClientUUID(),
            'X-API-Version': '1.0',
        };
    }
    async request(config) {
        config.headers = {
            ...this.headers,
            ...config.headers || {},
        };
        /**
         * 等待token准备好
         */
        if (!config.anonymous) {
            await prepareTokenDeferred.promise;
        }
        return this.httpClient(config)
            .then(RnDHttp.responseSuccessHandler, RnDHttp.responseFailHandler);
    }
    requestByChannelId(channelId, config) {
        if (!this._channelsMap.has(channelId)) {
            // @ts-ignore
            const newChannel = new RequestChannel(psHttp$);
            this._channelsMap.set(channelId, newChannel);
        }
        const channel = this._channelsMap.get(channelId);
        return channel.requestAsPromise(config);
    }
}
