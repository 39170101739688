const pattern = /---uniform-setting-locale=(\w+)/;
export const getCurrentLanguage = () => {
    const [, lang] = document.cookie.match(pattern) || [];
    if (!lang) {
        const host = window.location.host || '';
        if (host.indexOf('.zhihuiya.') > -1) {
            return 'cn';
        }
        else if (host.indexOf('.patsnap.') > -1) {
            return 'en';
        }
    }
    return lang;
};
export const getLanguageCnOrEn = () => {
    switch (getCurrentLanguage()) {
        case 'cn':
        case 'tw':
            return 'cn';
        default:
            return 'en';
    }
};
