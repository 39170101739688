const fontConfig = {
    cn: '',
    de: '',
    en: '',
    tw: '',
    jp: 'Arial, Helvetica, Meiryo,"ＭＳ Ｐゴシック","ＭＳ 明朝","ＭＳ Ｐ明朝"',
};
const fontClassName = 'websit-use-specified-font';
const styleSheetId = 'id-font-style-sheet';
const appendFontStyleSheet = (lang) => {
    const isExit = !!document.querySelector(`#${styleSheetId}`);
    if (isExit)
        return;
    const css = `
        .${fontClassName}, .${fontClassName} *{
            font-family: ${fontConfig[lang]};
        }
    `;
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = styleSheetId;
    style.innerHTML = css;
    head.insertBefore(style, head.childNodes[0]);
};
const addClassName = () => {
    const fontBox = document.documentElement;
    if (fontBox.className.indexOf(fontClassName) !== -1)
        return;
    fontBox.className += ` ${fontClassName}`;
};
export default (currentLang) => {
    try {
        if (fontConfig[currentLang]) {
            appendFontStyleSheet(currentLang);
            addClassName();
        }
    }
    catch (e) {
        console.log(e);
    }
};
