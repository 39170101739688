import initialize, { interceptAxios, handleLogout } from '@patsnap-utils/passport';
import { psAxios, setPostByFormPassport } from '@patsnap/common-ps-http';
import { UserCenterService, transformUrlProxy, SLD, Site } from '@patsnap-utils/biz';
import _ from 'lodash';
import { applySignInterceptors } from './sign';
import { sleep } from '@eureka/utils/task';
import qs from 'qs';
import { removeEurekaLoginCookie } from './set-eureka-login-cookie';
import { create } from '@eureka/utils/deferred';
export var SERVICE_FROM;
(function (SERVICE_FROM) {
    SERVICE_FROM["EUREKA"] = "s-core-eureka";
})(SERVICE_FROM || (SERVICE_FROM = {}));
const { set, get, isRegExp } = _;
const PASSPORT_URI = process.env.VUE_APP_PASSPORT_URI;
const CLIENT_ID = process.env.VUE_APP_PASSPORT_CLIENT_ID;
const CLIENT_NAME = process.env.VUE_APP_PASSPORT_CLIENT_NAME;
const baseAuthServer = transformUrlProxy(SLD.PASSPORT, PASSPORT_URI);
let _header = {};
const _passportDeferred = create();
export async function getAsyncToken() {
    const _passport = await _passportDeferred.promise;
    return _passport?.getAccessToken().then((token) => {
        if (!token) {
            throw new Error('token is empty');
        }
        return token;
    });
}
export const passportInterceptManager = {
    inject() {
        if (!psAxios.psInterceptors.passportRequestInterceptor) {
            interceptAxios(psAxios);
        }
    },
    eject() {
        psAxios.interceptors.request.eject(psAxios.psInterceptors.passportRequestInterceptor);
        psAxios.interceptors.response.eject(psAxios.psInterceptors.passportResponseInterceptor);
        delete psAxios.psInterceptors.passportRequestInterceptor;
        delete psAxios.psInterceptors.passportResponseInterceptor;
    },
};
function replaceLocation(url) {
    console.log('location replace:', url);
    window.location.replace(url);
}
export function stoper() {
    if (window.location.search.indexOf('noraven') > -1) {
        throw new Error('Local stop redirect');
    }
}
const handleExcludesUrlRegExps = (option) => {
    for (const rule of option.excludesUrlRegExps || []) {
        let pattern;
        let version = option.apiVersion;
        if (isRegExp(rule)) {
            pattern = rule;
        }
        else {
            pattern = rule.pattern;
            version = rule.version;
        }
        if (option.config?.url && pattern.test(option.config.url)) {
            /**
             * 如果 API （封装）未指定 header: X-API-Version，才使用 preset 中配置的 version
             */
            if (!('X-API-Version' in option.config.headers) && version) {
                option.config.headers['X-API-Version'] = version;
            }
            return option.config;
        }
    }
};
function revertString(str) {
    return str.split('').reverse().join('');
}
async function prepareTokenBase(option) {
    const initializeConfig = {
        baseAuthServer,
        clientId: CLIENT_ID,
        clientName: CLIENT_NAME,
        onTokenRefresh: () => {
            //
        },
        redirectToLogin(error) {
            option.preRedirectLogin && option.preRedirectLogin(error);
            console.warn('Check Session Error, Will Redirect To Login Page!');
            removeEurekaLoginCookie();
            stoper();
            const errcode = get(error, 'errcode');
            if (errcode) {
                replaceLocation(UserCenterService.createLogoutHref(true, { errorCode: errcode, banner_type: 'image' }));
            }
            else {
                replaceLocation(UserCenterService.createLogoutHref(true, { errorCode: 39999, banner_type: 'image' }));
            }
            return sleep(6000); // 正在跳转（大概6秒够了），后面的别执行了
        },
        /*  可选，允许修改 checksession 的参数 */
        syncTokenParamsModifier(params) {
            return { ...params /* , flag: 'analytics', */ };
        },
        detectResponseAsUnauthorized(error) {
            removeEurekaLoginCookie();
            const redirectUrl = get(error, 'response.data.redirectUrl');
            if (redirectUrl) {
                stoper();
                window.location.href = redirectUrl;
                throw error;
            }
            return get(error, 'response.status') === 401;
        },
        onPaymentRequired(error) {
            // 自己跳转到付费页面
            const errcode = get(error, 'errcode');
            const urlParams = {
                from: CLIENT_NAME,
                client_id: CLIENT_ID,
                redirect_uri: encodeURIComponent(window.location.href),
                errorCode: errcode,
            };
            const url = new Site({ sld: SLD.ACCOUNT, pathname: `user-settings/#/upper-limit${qs.stringify(urlParams, { addQueryPrefix: true })}` }).href;
            stoper();
            window.location.replace(url);
        },
    };
    const interceptorRequestSuccess = (config) => {
        if (config.headers) {
            config.headers['X-PatSnap-From'] = `w-${CLIENT_NAME}`;
        }
        config.headers = {
            ...config.headers,
            ..._header,
        };
        const excludesUrlConfig = handleExcludesUrlRegExps({
            config,
            excludesUrlRegExps: option.excludesUrlRegExps,
            apiVersion: option.apiVersion,
        });
        if (excludesUrlConfig)
            return excludesUrlConfig;
        return config;
    };
    // @ts-ignore
    psAxios.psInterceptors.nodeApiInterceptor = psAxios.interceptors.request.use(interceptorRequestSuccess, undefined);
    /* 清除 csrfTokenInterceptor */
    psAxios.interceptors.request.eject(psAxios.psInterceptors.csrfTokenInterceptor);
    passportInterceptManager.inject();
    window.customBeforeLogout = function customBeforeLogout() {
        // 此方法默认会被 Header V4点"登出"时调用， 用其他头时，登出时自己调用 handleLogout()
        return handleLogout();
    };
    option.callback && option.callback(psAxios);
    const passport = await initialize(initializeConfig);
    set(window, revertString('6csg9md32h'), function () {
        return passport.getToken('token') || '';
    });
    applySignInterceptors(psAxios);
    setPostByFormPassport(passport);
    _passportDeferred.resolve(passport);
    return passport;
}
/**
 * 更新 token base 请求的 header， 如 X-Site-Lang
 */
function updateTokenBaseRequestHeaders(headers) {
    _header = headers;
}
export { baseAuthServer, prepareTokenBase, updateTokenBaseRequestHeaders, };
