export const cn = {
    Tools: {
        Name: '研发工具',
        NewTechnology: '技术想法查新',
        Idea: '技术想法查新',
        TechnologyRoadmap: '行业技术路线',
        ApplicationExplore: '技术应用探索',
        CompetitorMonitoring: '竞争对手技术监控',
        Project: '技术预研报告',
        AIDisclosure: '交底书助手',
        Workflow: '材料合成工艺',
    },
    Workspace: '工作空间',
    Monitor: '监控洞察',
    Product: {
        MATERIAL: '新材料',
        NEV: 'NEVs',
    },
    ProductDesc: {
        MATERIAL: '切换至材料研发情报库',
        NEV: 'NEVs',
    },
    TryMaterial: '试用材料版',
    TryMaterialTip: '你也可以从这里开始体验材料版',
    GotIt: '知道了',
};
export const en = {
    Tools: {
        Name: 'R&D Tools',
        NewTechnology: 'Novelty Check',
        Idea: 'Novelty Check',
        TechnologyRoadmap: 'Technology Roadmap',
        ApplicationExplore: 'Application Exploration',
        CompetitorMonitoring: 'Competitor Technology Monitoring',
        Project: 'Technical Research Report',
        AIDisclosure: 'Invention Disclosure Assistant',
        Workflow: 'Material synthesis process',
    },
    Workspace: 'Workspace',
    Monitor: 'Monitoring Insights',
    Product: {
        MATERIAL: 'Materials',
        NEV: 'NEVs',
    },
    ProductDesc: {
        MATERIAL: 'Switch to Materials',
        NEV: 'NEVs',
    },
    TryMaterial: 'Try Eureka Materials',
    TryMaterialTip: 'You can also start experiencing the Materials version here',
    GotIt: 'Got it',
};
export const jp = {
    Tools: {
        Name: '研究開発ツール',
        NewTechnology: '新規性チェック',
        Idea: '新規性チェック',
        TechnologyRoadmap: 'テクニカルロードマップ',
        ApplicationExplore: '技術応用の探索',
        CompetitorMonitoring: '競合他社のテクノロジーモニタリング',
        Project: '技術予備調査レポート',
        AIDisclosure: '明細書作成アシスタント',
        Workflow: '合成工程の可視化',
    },
    Workspace: 'ワークスペース',
    Monitor: 'インサイトの監視',
    Product: {
        MATERIAL: '新素材',
        NEV: 'NEVs',
    },
    ProductDesc: {
        MATERIAL: 'マテリアルに切り替え',
        NEV: 'NEVs',
    },
    TryMaterial: '材料版を試す',
    TryMaterialTip: 'ここから材料版を体験することもできます',
    GotIt: '了解',
};
export const tw = {
    Tools: {
        Name: '研發工具',
        NewTechnology: '技術想法查新',
        Idea: '技術想法查新',
        TechnologyRoadmap: '行業技術路線',
        ApplicationExplore: '技術應用探索',
        CompetitorMonitoring: '競爭對手技術監控',
        Project: '技術預研報告',
        AIDisclosure: '交底書助手',
        Workflow: '材料合成工藝',
    },
    Workspace: '工作空間',
    Monitor: '監控洞察',
    Product: {
        MATERIAL: '新材料',
        NEV: 'NEVs',
    },
    ProductDesc: {
        MATERIAL: '切換至材料研發情報庫',
        NEV: 'NEVs',
    },
    TryMaterial: '試用材料版',
    TryMaterialTip: '你也可以從這裡開始體驗材料版',
    GotIt: '知道了',
};
