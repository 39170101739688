export function isRnDError(err) {
    return err && err._type === 'RndError';
}
export class RndError extends Error {
    code;
    _type = 'RndError';
    constructor(code, message) {
        super(message || code);
        this.code = code;
        this.code = code;
    }
}
