import { BaseHttpService } from './base-http-service';
import { ExceptionFactory, HttpSuccessException, HttpFailException, isAxiosError } from './exception';
export const HttpWorkspaceException = ExceptionFactory('$$WorkspaceHttp');
export class WorkspaceHttp extends BaseHttpService {
    httpClient;
    errorHandlerService;
    constructor(httpClient, errorHandlerService) {
        super(errorHandlerService);
        this.httpClient = httpClient;
        this.errorHandlerService = errorHandlerService;
        this.configErrorHandler();
    }
    configErrorHandler() {
        this.errorHandlerService.addHandler(async (error) => {
            if (HttpSuccessException.is(error)) {
                this._errorDisplayProxy({
                    code: `${error.data.error_code}`,
                    params: error.data.error_params,
                });
                return true;
            }
            if (!HttpFailException.is(error)) {
                return false;
            }
            const httpFailExceptionData = error.response?.data;
            if (!httpFailExceptionData) {
                return false;
            }
            this._errorDisplayProxy({
                code: `${httpFailExceptionData.numeric_error_code}`,
                params: httpFailExceptionData.error_params,
                message: httpFailExceptionData.error_message,
            });
            return true;
        });
    }
    request(config) {
        config.headers = {
            'X-API-Version': '2.0',
        };
        return this.httpClient(config).then(res => {
            const data = res.data;
            if (data.status === false) {
                throw HttpSuccessException.mark(res);
            }
            return res.data;
        }, error => {
            if (!isAxiosError(error)) {
                throw error;
            }
            error = HttpFailException.mark(error);
            throw error;
        });
    }
}
