import { LocalStorageService } from '@eureka/services/src/storage-service';
function getInnoParams() {
    const url = window.location.href;
    const search = url.split('?')[1];
    if (!search)
        return new URLSearchParams();
    const hashIndex = search.indexOf('#');
    const params = new URLSearchParams(hashIndex === -1 ? search : search.slice(0, hashIndex));
    return params;
}
export const refreshToken = () => {
    const params = getInnoParams();
    const from = params.get('from') || '';
    const uid = params.get('uid') || '';
    let InnoFlag = null;
    try {
        InnoFlag = LocalStorageService.get('InnoFlag');
        if (from === 'inno' && uid !== InnoFlag) {
            LocalStorageService.set('InnoFlag', uid);
            LocalStorageService.delete('patsnap-authorizations');
            window.location.reload();
        }
    }
    catch (error) {
        console.error('Warn: Get InnoFlag error');
    }
};
