import _ from 'lodash';
import psHttp, { psAxios } from '@patsnap/common-ps-http';
import { createUniformSetting } from '@patsnap/uniform-setting';
import { HeaderService, SLD, Site } from '@patsnap-utils/biz';
import { isAnonymousError } from '@eureka/services/src/exception';
import { prepareTokenBase, updateTokenBaseRequestHeaders, passportInterceptManager } from './enable-token-base';
import { handleLogout } from '@patsnap-utils/passport';
import setWebsiteFont from './configure-website-font';
import { customProductsLinkInUserCenter } from './custom-links';
import { getCurrentLanguage } from '@eureka/language-tool';
import { addTrialService } from '@eureka/services/src/add-trial-service';
import browser from './browser';
import { accountDeferred, prepareTokenDeferred } from '@eureka/utils/account';
import { removeEurekaLoginCookie, setEurekaLoginCookie } from './set-eureka-login-cookie';
const supports = ['cn', 'en', 'jp', 'tw'];
const lang = getCurrentLanguage();
const { includes, filter, get } = _;
export function startup(startupOption) {
    /* FIXME : get from for trial user */
    addTrialService.ENABLE_AUTO_ADD_TRIAL && addTrialService.handleUserTrialFromSite();
    browser();
    const promise = startupOption.preset
        ? prepareTokenBase(startupOption.preset).then((p) => {
            setEurekaLoginCookie();
            prepareTokenDeferred.resolve(p);
            /* send auth ready signal */
            return p;
        }).catch(e => {
            if (isAnonymousError(e)) {
                // 只有在startup的preset.preRedirectLogin中手动throw new AnonymousError才会触发
                passportInterceptManager.eject();
                accountDeferred.reject(e);
            }
        })
        : Promise.resolve();
    // @ts-ignore
    return promise.then((p) => {
        const headerService = startupOption.headerService || new HeaderService();
        headerService.logoHref = new Site({ sld: SLD.EUREKA, pathname: 'home' }).href;
        return Promise.all([
            startupOption.pageInit().catch(e => {
                if (!addTrialService.ENABLE_AUTO_ADD_TRIAL)
                    return Promise.reject(e);
            }),
            headerService.sync({ http: psHttp, uri: process.env.VUE_APP_ACCOUNT_SERVICE_URI }).finally(() => {
                customProductsLinkInUserCenter(headerService);
                headerService.visibleProducts = true;
                headerService.account && accountDeferred.resolve(headerService.account);
            }).then(() => {
                if (startupOption.disableTrial) {
                    return;
                }
                return addTrialService.addTrial(headerService, p);
            }),
            (() => {
                const setting = createUniformSetting({
                    verbose: true,
                    axiosInstance: psAxios,
                    loggedIn: true,
                    baseAccountUrl: process.env.VUE_APP_ACCOUNT_SERVICE_URI,
                    locale: {
                        supports,
                    },
                    region: process.env.VUE_APP_DEPLOY_ENV,
                });
                // @ts-ignore
                return setting.locale.getLocale().then((data) => {
                    const locale = data.user || data.default;
                    setWebsiteFont(locale);
                    updateTokenBaseRequestHeaders({ 'X-Site-Lang': locale.toUpperCase() });
                    return { locale, setting };
                });
            })()
        ]).then((data) => {
            const [pageData, , { locale, setting }] = data;
            /* FIXME */
            addTrialService.removeUserTrialFromSite();
            /* 如果 cookies 中存储的语言和持久化的语言不匹配，则刷新页面 */
            if (lang !== locale) {
                window.location.reload();
                return;
            }
            /* WhiteLabel 时，隐藏 PatSnap Logo */
            if (headerService.whiteLabel) {
                headerService.logoVisible = false;
            }
            const { locales } = headerService;
            headerService.locales = filter(locales, locale => includes(supports, locale.key)).map(({ ...rest }) => {
                return {
                    ...rest,
                    active: rest.key === locale,
                };
            });
            /* 注册切换语言函数 */
            // eslint-disable-next-line @typescript-eslint/ban-types
            headerService.beforeLocaleChange = function beforeLocaleChange(localeKey, done) {
                setting.locale.setLocale(localeKey).then(() => {
                    window.location.reload();
                    done();
                });
            };
            /* 注册登出回调函数 */
            // eslint-disable-next-line @typescript-eslint/ban-types
            headerService.userCenterService.logoutHandler = async function logout(done, logoutHref) {
                handleLogout().then((data) => {
                    removeEurekaLoginCookie();
                    setTimeout(() => {
                        const href = get(data, 'data.redirect_url', logoutHref);
                        window.location.href = href;
                    }, 1000);
                });
            };
            /** header头切换租户成功后页面跳转 */
            headerService.switchTenantHandler = async (tenantId, done) => {
                return done().then(() => {
                    window.location.href = new Site({ sld: SLD.EUREKA, pathname: 'home' }).href;
                });
            };
            const account = headerService.account;
            if (account) {
                import(/* webpackChunkName: "defer" */ './defer').then(({ defer }) => {
                    defer(startupOption, account, locale);
                });
            }
            return [headerService, locale, pageData, setting];
        }).catch(() => {
            if (!addTrialService.ENABLE_AUTO_ADD_TRIAL) {
                window.location.href = new Site({ sld: SLD.EUREKA, pathname: '403/' }).href;
            }
        });
    });
}
