export function sleep(timeout) {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
}
export function withLoading(self, p, useLoading = true) {
    if (!useLoading) {
        return p;
    }
    self.loading = true;
    return p.finally(() => {
        self.loading = false;
    });
}
export function withFieldLoading(self, p, field) {
    self[field] = true;
    return p.finally(() => {
        self[field] = false;
    });
}
export class TaskSource {
    _promise;
    _resolve;
    _reject;
    _fired = false;
    constructor() {
        this._promise = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }
    get Promise() {
        return this._promise;
    }
    Resolve(t) {
        if (this._fired) {
            console.error('Can not Resolve fired source');
            return false;
        }
        this._fired = true;
        this._resolve(t);
        return true;
    }
    // todo
    TryResolve(t) {
        if (this._fired) {
            return false;
        }
        return this.Resolve(t);
    }
    Reject(e) {
        if (this._fired) {
            console.error('Can not Resolve fired source');
            return false;
        }
        this._fired = true;
        this._reject(e);
        return true;
    }
    TryReject(e) {
        if (this._fired) {
            return false;
        }
        return this.Reject(e);
    }
    get Fired() {
        return this._fired;
    }
}
