import { Site, SLD } from '@patsnap-utils/biz';
import { getCurrentLanguage } from '@eureka/language-tool';
import '@patsnap-ui/icon/assets/solid/HistoryRecord.svg';
import '@patsnap-ui/icon/assets/userpackage.svg';
export function customProductsLinkInUserCenter(headerService) {
    const locale = getCurrentLanguage();
    const buildin = headerService.userCenterService.beforeRenderMenuItems;
    headerService.userCenterService.beforeRenderMenuItems = function beforeRenderMenuItems(items) {
        const _items = buildin(items);
        _items.splice(1, 0, {
            href: new Site({ sld: SLD.EUREKA, pathname: 'history/' }).href,
            label: ({
                cn: '历史记录',
                en: 'History',
                jp: '履歴',
                tw: '歷史記錄',
            })[locale],
            icon: 'SolidHistoryRecord',
            key: 'history',
        });
        _items.splice(0, 0, {
            href: new Site({ sld: SLD.EUREKA, pathname: 'user-setting/amount' }).href,
            label: ({
                cn: '用量',
                en: 'Usage',
                jp: '可用',
                tw: '用量',
            })[locale],
            icon: 'Userpackage',
            key: 'package',
        });
        return _items;
    };
}
