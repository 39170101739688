import { __decorate, __metadata } from "tslib";
import psHttp from '@patsnap/common-ps-http';
import { environment } from './environment';
import { ErrorHandlerService } from './error-handler-service';
import { SystemService } from './system-service';
import { singleton } from './flow';
import { RnDHttp } from './rnd-http';
import { AnalyticsHttp } from './analytics-http';
import { WorkspaceHttp } from './workspace-http';
import { HomeHttp } from './home-http';
import { PaperHttp } from './paper-http';
import { AccountHttp } from './account-http';
export class Container {
    get SystemService() {
        return new SystemService(environment);
    }
    get ErrorHandlerService() {
        return new ErrorHandlerService(this.SystemService);
    }
    get RnDHttp() {
        return new RnDHttp(psHttp, this.ErrorHandlerService);
    }
    get AnalyticsHttp() {
        return new AnalyticsHttp(psHttp, this.ErrorHandlerService);
    }
    get WorkspaceHttp() {
        return new WorkspaceHttp(psHttp, this.ErrorHandlerService);
    }
    get HomeHttp() {
        return new HomeHttp(psHttp, this.ErrorHandlerService);
    }
    get PaperHttp() {
        return new PaperHttp(psHttp, this.ErrorHandlerService);
    }
    get AccountHttp() {
        return new AccountHttp(psHttp, this.ErrorHandlerService);
    }
}
__decorate([
    singleton(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Container.prototype, "SystemService", null);
__decorate([
    singleton(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Container.prototype, "ErrorHandlerService", null);
__decorate([
    singleton(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Container.prototype, "RnDHttp", null);
__decorate([
    singleton(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Container.prototype, "AnalyticsHttp", null);
__decorate([
    singleton(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Container.prototype, "WorkspaceHttp", null);
__decorate([
    singleton(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Container.prototype, "HomeHttp", null);
__decorate([
    singleton(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Container.prototype, "PaperHttp", null);
__decorate([
    singleton(),
    __metadata("design:type", Object),
    __metadata("design:paramtypes", [])
], Container.prototype, "AccountHttp", null);
export const container = new Container();
