import qs from 'qs';
import _ from 'lodash';
import { SLD, Site } from '@patsnap-utils/biz';
export function safeGetLocaleStorage(key) {
    try {
        return localStorage.getItem(key);
    }
    catch (e) {
        console.error(e);
        return null;
    }
}
export function getVar(key, type = 'string') {
    // todo!!!
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (_.has(query, key)) {
        const value = _.get(query, key);
        if (_.isNull(value) || _.isUndefined(value)) {
            return true;
        }
        if (type === 'boolean') {
            if (_.lowerCase(value) === 'false') {
                return false;
            }
            else {
                return true;
            }
        }
        return value;
    }
    const res = safeGetLocaleStorage(key);
    if (_.isNull(res)) {
        return undefined;
    }
    return res;
}
export const CHANNEL_SOURCE = 'west_eureka';
export let REGION = new Site({ sld: SLD.EUREKA }).env;
const debug_region = getVar('debug_region');
if (debug_region) {
    console.warn(`使用替换Region ${REGION} => ${debug_region}`);
    REGION = debug_region;
}
