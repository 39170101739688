import { container } from '@eureka/services';
import { Toast } from 'patsnap-biz';
import _ from 'lodash';
/**
 * 添加全局处理，如果是上限错误，就弹出活动弹窗。用的地方自行抛LimitError即可
 */
container.RnDHttp.errorHandlerService.addHandler(async (error, e) => {
    if (error?.isLimitError) {
        const { invitationServiceInstance } = await import('@eureka/invitation-reward/src/inviation.service');
        invitationServiceInstance.init(error.activityTriggerType, error.originError);
        return true;
    }
    return false;
});
/**
 * 全局HTTP错误码提示
 */
export const registerHttpErrorTooltip = (i18n) => {
    container.ErrorHandlerService.start();
    container.ErrorHandlerService.setErrorDisplay((err) => {
        Toast({
            type: 'error',
            message: `[${err.code}] ${err.message}`,
            duration: 5000,
            showClose: true,
            dangerouslyUseHTMLString: true,
        });
    });
    container.RnDHttp.setErrorDisplay((data) => {
        const errorCodeI18nKey = `commonStatusCode.${data.code}`;
        const defaultErrorCodeI18nKey = 'commonStatusCode.400';
        const message = i18n.te(errorCodeI18nKey) ? i18n.t(errorCodeI18nKey, _.get(data, 'params', {})) : i18n.t(defaultErrorCodeI18nKey);
        Toast({
            type: 'error',
            message,
            duration: 5000,
            showClose: true,
            dangerouslyUseHTMLString: true,
        });
    });
};
