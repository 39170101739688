import { sign } from '@patsnap/common-ps-http/dist/crypto';
// 引入拦截器
import { registerRequestSignInterceptor } from '@patsnap/common-ps-http/dist/RequestSignInterceptor';
const coreBasicApiUrlPrefix = `${process.env.VUE_APP_CORE_BASIC_SERVICE_URI}/core-basic-api`;
function fetchWithTimeout(url, options, timeout) {
    return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
            reject(new Error('timeout'));
        }, timeout);
        fetch(url, options).then(res => {
            clearTimeout(timer);
            resolve(res);
        }).catch(err => {
            clearTimeout(timer);
            reject(err);
        });
    });
}
export function applySignInterceptors(axios) {
    // 注册拦截器
    const requestSignInterceptor = registerRequestSignInterceptor(
    // axios实例
    axios, {
        // [必填] 决定是否需要拦截错误的请求
        // eslint-disable-next-line
        detectResponseShouldRefresh(response) {
            return false;
        },
        // [必填] 获取服务器的时间戳(毫秒),当detectResponseShouldRefresh返回true的时候调用
        getServiceTimeStamp() {
            const now = Date.now();
            return fetchWithTimeout(`${coreBasicApiUrlPrefix}/analytics/security/timestamp?lt=${now}`, {}, 11000)
                .then(res => res.json())
                .then(d => parseInt(d.data))
                .catch(() => Date.now());
        },
        // [可选] 决定是否需要添加请求签名,不传此参数,默认所有接口都会加请求签名头
        detectShouldSignRequest(config, fullUrl) {
            return fullUrl.includes('eureka-service.zhihuiya.com') || fullUrl.includes('paper-service.zhihuiya.com');
        },
    });
    // 如果你想一开始就同步下服务器时间调用下面的方法,再调用自己的业务请求
    requestSignInterceptor.syncServiceTime();
    requestSignInterceptor.sign = sign;
}
