export const cn = {
    imagePreprocessor: {
        cropText: '裁剪',
        deleteText: '删除',
        editImage: '旋转或裁剪图片',
        fileLimit: '文件小于4M',
        isUploading: '正在上传中...',
        rotateText: '旋转',
        supportImg: '支持常用图片格式(jpg, png)',
        tagDrap: '点击上传或者拖拽一个图片放到这里',
        uploadText: '上传',
        overSizeMsg: '图片太大啦，',
        overSizeReason: '不能超过4MB哦！',
        typeErrorMsg: '无法识别上传的图片呢...',
        typeErrorReason: '只能识别JPG和PNG哦',
        unknownError: '服务器存在异常，请联系系统管理员或稍后再试。',
        disabledUpload: '系统管理员已禁用该功能',
    },
    designSearch: {
        nodataTip: {
            reasonTitle: '未找到与您的检索语句或者图片匹配的结果，建议:',
            reasonList: '上传更加清晰的图片-&-裁剪图片的关键内容-&-旋转图片方向-&-检查检索语句拼写是否正确-&-检查检索语句标点符号',
        },
        designPatents: '外观设计',
        utilityPatents: '实用新型',
        successfullyDeleted: '删除成功',
        deleteConfirm: '确定删除该上传图片？',
        deleteTitle: '删除上传的图片',
    },
    smartSearch: {
        nodataTip: {
            reasonTitle: '没有找到与您的检索语句匹配的结果，建议:',
            reasonList: '检查拼写是否正确-&-检查标点符号-&-尝试使用同义词-&-扩大搜索范围',
        },
    },
    noveltySearch: {
        errorTip: {
            title: '没有找到近似的结果。',
            tip1: '这个想法的创新度不错，也许您可以考虑把它申请专利。',
            tip2: '如果您对方案描述还有补充完善，也可以做持续的调整，多次验证。',
        },
    },
    unversalSearch: {
        title: 'Eureka 一下！',
        subTitle: '描述您现在遇到的技术难点或期望获得的技术突破，我们将在浩瀚的知识海洋中为您找到相关度最高的现有技术方案。',
        searchBtn: '搜 索',
        searchImageBtn: '图像检索',
        imageSearchTip: '进行图像检索',
        historyLink: '检索历史',
        maxQueryLengthError: '检索内容长度过长',
        requiredError: '请输入检索内容后，再进行检索',
        searchPlaceholder: '请输入技术关键词或公司名, 快速定位全球专利',
        searchImagePlaceholder: '请输入关键字、公司名',
        suggestion: {
            HISTORY: '历史记录',
            AUTOCOMPLETE: '推荐词',
            COMPANY: '推荐公司',
            BENEFIT: '猜你想搜',
        },
    },
    global: {
        btn: {
            confirm: '确定',
            submit: '提交',
            delete: '删除',
            cancel: '取消',
            showless: '收起',
            clear: '清空',
        },
        solutionType: {
            PATENT: '专利',
            NPL: '文献',
        },
    },
};
export const en = {
    imagePreprocessor: {
        cropText: 'Crop',
        deleteText: 'Delete',
        editImage: 'Rotate or Crop the image',
        fileLimit: 'File size limit is 4MB',
        isUploading: 'Uploading...',
        rotateText: 'Rotate',
        supportImg: 'Supports JPG and PNG',
        tagDrap: 'Click Upload or drag & drop image here',
        uploadText: 'Upload',
        overSizeMsg: 'The image is too large to upload.',
        overSizeReason: 'Upload Images cannot exceed 4 MB',
        typeErrorMsg: 'Sorry, the image upload failed.',
        typeErrorReason: 'Not a valid image (JPG, PNG)',
        unknownError: 'The server is abnormal, please contact the system administrator or try again later.',
        disabledUpload: 'System administrator has disabled this feature',
    },
    designSearch: {
        nodataTip: {
            reasonTitle: 'No results match your query or image. We suggest you:',
            reasonList: 'Upload a clearer image-&-Crop only the key content of the image-&-Rotate the image-&-Check the spelling of the search statement-&-Check the punctuation of the search statement',
        },
        designPatents: 'Design patents',
        utilityPatents: 'Utility models',
        successfullyDeleted: 'Successfully deleted',
        deleteConfirm: 'Are you sure you want to delete the uploaded image?',
        deleteTitle: 'Delete Uploaded image',
    },
    smartSearch: {
        nodataTip: {
            reasonTitle: 'No patents match your query this time. Suggestions:',
            reasonList: 'Check your spelling-&-Check your punctuation-&-Try using synonyms-&-Broaden your search',
        },
    },
    noveltySearch: {
        errorTip: {
            title: 'No similar results found.',
            tip1: 'Your idea could be novel.',
            tip2: 'Consider adjusting your input to more thoroughly assess novelty.',
        },
    },
    unversalSearch: {
        title: 'Get started in Eureka!',
        subTitle: 'Describe the technical challenge you are encountering or your technical breakthrough goal and our powerful search function will retrieve the most relevant technology solutions for you.',
        searchBtn: 'Search',
        searchImageBtn: 'Image Search',
        imageSearchTip: 'Upload image to search',
        historyLink: 'History',
        maxQueryLengthError: 'Search content length exceeded',
        requiredError: 'Enter search contents before proceeding',
        searchPlaceholder: 'Please enter technical keywords or company names',
        searchImagePlaceholder: 'Input keywords or company name',
        suggestion: {
            HISTORY: 'History',
            AUTOCOMPLETE: 'Recommend Keywords',
            COMPANY: 'Recommend Company',
            BENEFIT: 'You might want to know',
        },
    },
    global: {
        btn: {
            confirm: 'Confirm',
            submit: 'Submit',
            delete: 'Delete',
            cancel: 'Cancel',
            showless: 'Collapse',
            clear: 'Clear',
        },
        solutionType: {
            PATENT: 'Patent',
            NPL: 'Literature',
        },
    },
};
export const jp = {
    imagePreprocessor: {
        cropText: '切り抜き',
        deleteText: '削除する',
        editImage: '画像を回転またはトリミング',
        fileLimit: 'ファイルサイズ４M以下',
        isUploading: 'アップロード中...',
        rotateText: '回転させる',
        supportImg: '画像フォーマト(jpg, png)',
        tagDrap: '画像をアップロードまたはドラッグしてここにドロップします',
        uploadText: 'アップロードする',
        overSizeMsg: '画像が大きすぎ！',
        overSizeReason: '４MBを超えることはできません！',
        typeErrorMsg: 'アップロードされた画像を識別できません。',
        typeErrorReason: 'JPGとPNGしか識別できません',
        unknownError: 'サーバーが異常です。システム管理者に連絡するか、後で再試行してください。',
        disabledUpload: 'システム管理者がこの機能を無効にしました',
    },
    designSearch: {
        nodataTip: {
            reasonTitle: '検索結果または画像と一致する結果が見つかりませんでした，提案:',
            reasonList: 'より鮮明な画像をアップロードする-&-画像の主要なコンテンツをトリミングする-&-画像の方向を回転させる-&-検索ステートメントのスペルが正しいかどうかを確認してください-&-検索ステートメントの句読点を確認する',
        },
        designPatents: '意匠',
        utilityPatents: '実用新案',
        successfullyDeleted: '削除しました',
        deleteConfirm: 'アップロードした画像を必ず削除してください?',
        deleteTitle: 'アップロードした画像を削除する',
    },
    smartSearch: {
        nodataTip: {
            reasonTitle: '検索結果と一致する結果が見つかりませんでした，提案:',
            reasonList: 'つづりが正しいか確認してください-&-句読点をチェックする-&-同義語を使ってみる-&-検索範囲を広げる',
        },
    },
    noveltySearch: {
        errorTip: {
            title: '内容が類似するものは見つかりませんでした。',
            tip1: '類似するものはありません。',
            tip2: '革新的なアイデアです。特許化する価値があるかもしれません。',
        },
    },
    unversalSearch: {
        title: 'Eurekaしてみましょう！',
        subTitle: '技術について、今お持ちする問題点あるいはご期待されているブレイクスルーを説明して頂ければ、膨大な知識の海から最も適切な既存の技術手段を見出します。',
        searchBtn: '検 索',
        searchImageBtn: '画像検索',
        imageSearchTip: '画像検索を行う',
        historyLink: '検索履歴',
        maxQueryLengthError: '検索内容が長すぎています。',
        requiredError: '検索条件を入力した後、再度検索してください',
        searchPlaceholder: '技術キーワードまたは企業名でグローバル特許を迅速に検索',
        searchImagePlaceholder: 'キーワード、会社名を入力してください',
        suggestion: {
            HISTORY: '履歴',
            AUTOCOMPLETE: 'おすすめのキーワード',
            COMPANY: 'おすすめ企業',
            BENEFIT: 'あなたが知りたいと思う',
        },
    },
    global: {
        btn: {
            confirm: '確定',
            submit: '提出',
            delete: '削除',
            cancel: 'キャンセル',
            showless: '折り畳む',
            clear: 'クリア',
        },
        solutionType: {
            PATENT: '特許',
            NPL: '文献',
        },
    },
};
export const tw = {
    imagePreprocessor: {
        cropText: '裁剪',
        deleteText: '刪除',
        editImage: '旋轉或裁剪圖像',
        fileLimit: '文件小於4M',
        isUploading: '正在上傳中...',
        rotateText: '旋轉',
        supportImg: '支持常用圖片格式(jpg, png)',
        tagDrap: '點擊上傳或者拖拽一個圖片放到這裡',
        uploadText: '上傳',
        overSizeMsg: '圖片太大啦，',
        overSizeReason: '不能超過4MB哦！',
        typeErrorMsg: '無法識別上傳的圖片呢...',
        typeErrorReason: '只能識別JPG和PNG哦',
        unknownError: '服務器存在異常，請聯系系統管理員或稍後再試。',
        disabledUpload: '系統管理員已禁用該功能',
    },
    designSearch: {
        nodataTip: {
            reasonTitle: '未找到與您的檢索語句或者圖片匹配的結果，建議:',
            reasonList: '上傳更加清晰的圖片-&-裁剪圖片的關鍵內容-&-旋轉圖片方向-&-檢查檢索語句拼寫是否正確-&-檢查檢索語句標點符號',
        },
        designPatents: '外觀設計',
        utilityPatents: '實用新型',
        successfullyDeleted: '刪除成功',
        deleteConfirm: '確定刪除該上傳的圖片?',
        deleteTitle: '刪除上傳的圖片',
    },
    smartSearch: {
        nodataTip: {
            reasonTitle: '沒有找到與您的檢索語句匹配的結果，建議:',
            reasonList: '檢查拼寫是否正確-&-檢查標點符號-&-嘗試使用同義詞-&-擴大搜索範圍',
        },
    },
    noveltySearch: {
        errorTip: {
            title: '沒有找到近似的結果。',
            tip1: '這個想法的創新度不錯，也許您可以考慮把它申請專利。',
            tip2: '如果您對方案描述還有補充完善，也可以做持續的調整，多次驗證。',
        },
    },
    unversalSearch: {
        title: 'Eureka 一下！',
        subTitle: '描述您現在遇到的技術難點或期望獲得的技術突破，我們將在浩瀚的知識海洋中為您找到相關度最高的現有技術方案。',
        searchBtn: '搜 索',
        searchImageBtn: '圖像檢索',
        imageSearchTip: '進行圖像檢索',
        historyLink: '檢索歷史',
        maxQueryLengthError: '檢索內容長度過長',
        requiredError: '請輸入檢索內容後，再進行檢索',
        searchPlaceholder: '請輸入技術關鍵字或公司名稱, 快速定位全球專利',
        searchImagePlaceholder: '請輸入關鍵字、公司名',
        suggestion: {
            HISTORY: '歷史記錄',
            AUTOCOMPLETE: '推薦詞',
            COMPANY: '推薦公司',
            BENEFIT: '猜你想搜',
        },
    },
    global: {
        btn: {
            confirm: '確定',
            submit: '提交',
            delete: '刪除',
            cancel: '取消',
            showless: '收起',
            clear: '清空',
        },
        solutionType: {
            PATENT: '專利',
            NPL: '文獻',
        },
    },
};
export const inputUniversalLocale = {
    cn, en, jp, tw,
};
