/* eslint-disable camelcase */
import { startsWith } from 'lodash';
export function isAxiosError(error) {
    if (!error) {
        return false;
    }
    return error.isAxiosError;
}
export function ExceptionFactory(tag) {
    const symbol = Symbol.for(tag + 'ExceptionSymbol');
    return {
        symbol,
        mark(error) {
            return {
                ...error,
                klass: symbol,
            };
        },
        is(error) {
            if (!error) {
                return false;
            }
            return error.klass === symbol;
        },
    };
}
export function ResponseExceptionFactory(tag) {
    const symbol = Symbol.for(tag + 'ResponseExceptionSymbol');
    return {
        symbol,
        mark(error) {
            return {
                ...error,
                klass: symbol,
            };
        },
        is(error) {
            if (!error) {
                return false;
            }
            return error.klass === symbol;
        },
    };
}
// #region AsyncException
const asyncCancelErrorSymbol = Symbol.for('$$AsyncCancelError');
export class AsyncCancelError extends Error {
    cls = asyncCancelErrorSymbol;
}
export function isAsyncCancelError(e) {
    return e instanceof AsyncCancelError || (e?.cls === asyncCancelErrorSymbol);
}
export const BiHttpException = ExceptionFactory('$$BiHttpException');
/**
 * http code != 200 时的错误
 */
export const HttpFailException = ExceptionFactory('CoreBaseException');
/**
 * http code 200 时的错误
 */
export const HttpSuccessException = ResponseExceptionFactory('CoreBaseDataException');
// #region AnonymousException
const anonymousErrorSymbol = Symbol.for('$$AnonymousError');
export class AnonymousError extends Error {
    cls = anonymousErrorSymbol;
}
export function isAnonymousError(e) {
    return e instanceof AnonymousError || (e?.cls === anonymousErrorSymbol);
}
// #region HttpStatusError
export var E_HttpStatusErrorType;
(function (E_HttpStatusErrorType) {
    E_HttpStatusErrorType["Offline"] = "Offline";
    E_HttpStatusErrorType["XX500"] = "XX500";
    E_HttpStatusErrorType["XX400"] = "XX400";
    E_HttpStatusErrorType["Unknown"] = "";
})(E_HttpStatusErrorType || (E_HttpStatusErrorType = {}));
const HttpStatusErrorSymbol = Symbol.for('$$HttpStatusError');
export class HttpStatusError extends Error {
    axiosError;
    netErrorType;
    constructor(axiosError, netErrorType) {
        super('HttpStatusError');
        this.axiosError = axiosError;
        this.netErrorType = netErrorType;
    }
    cls = HttpStatusErrorSymbol;
}
export function isHttpStatusError(e) {
    return e instanceof HttpStatusError || (e?.cls === HttpStatusErrorSymbol);
}
export class LimitError extends Error {
    axiosError;
    isLimitError = false;
    originError;
    activityTriggerType;
    constructor(message, axiosError) {
        super(message);
        this.axiosError = axiosError;
        this.isLimitError = true;
        this.originError = axiosError || null;
        this.activityTriggerType = message;
    }
}
export function getHttpStatusErrorType(e) {
    const status = e?.response?.status;
    if (!status) {
        return E_HttpStatusErrorType.Offline;
    }
    if (startsWith(status.toString(), '4')) {
        return E_HttpStatusErrorType.XX400;
    }
    if (startsWith(status.toString(), '5')) {
        return E_HttpStatusErrorType.XX500;
    }
    return E_HttpStatusErrorType.Unknown;
}
// #endregion
