import { __decorate, __metadata } from "tslib";
import { container } from '../container';
import { MergeApi } from '../request.decorator';
import { CHANNEL_SOURCE, getVar } from '@eureka/utils/environment';
import qs from 'qs';
export class CommonApis {
    static getSolutionData({ solution, fields, finally_query, key_words, doc_lang, }) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/common/solution/specific-data`,
            data: {
                solution,
                fields,
                finally_query,
                key_words,
                doc_lang,
            },
        }).then(res => {
            return res.data;
        });
    }
    // 添加试用
    static addTrial(fromSite, extendBody) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/account-privilege/add-trial?from=${fromSite}`,
            data: extendBody,
        });
    }
    static needAddTrial() {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/account-privilege/need-add-trial`;
        return container.RnDHttp.post({
            url,
        });
    }
    /**
     * 邀请注册入口初始化接口
     */
    static getInviteRegisterInfo() {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/activity/invite-register/info`;
        return container.RnDHttp.get({
            url,
        });
    }
    /**
     * 获取用户激励反馈次数
     */
    static getFeedbackInfo() {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/activity/feedback/info`;
        return container.RnDHttp.get({
            url,
        });
    }
    /**
     * 用户提交激励反馈
     */
    static postSubmitFeedback(params) {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/activity/feedback`;
        return container.RnDHttp.post({
            url,
            data: params,
        });
    }
    /**
     * 获取公司信息
     */
    static getCompanyInfo(companyName) {
        const params = {
            companyName,
        };
        const url = `${process.env.VUE_APP_PASSPORT_URI}/public/tyc/companies`;
        return container.RnDHttp.get({
            url,
            params,
        });
    }
    /**
     * 获取领取状态
     */
    static getReceiveInit() {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/activity/receive/init`;
        return container.RnDHttp.get({
            url,
        });
    }
    /**
     * 获取用户基本信息
     */
    static getUserInfo(from = '') {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/activity/information/info`;
        return container.RnDHttp.get({
            url,
            params: {
                from,
            },
        });
    }
    /**
     * 非活动弹窗内完善用户信息, 在首页、详情页
     */
    static setUserInfo(data) {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/save-user-information`;
        return container.RnDHttp.post({
            url,
            data,
        });
    }
    /**
     * 完善用户信息
     */
    static postPerfectInformation(data) {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/activity/information`;
        return container.RnDHttp.post({
            url,
            data,
        });
    }
    /**
     * 获取公钥
     */
    static getPublicKeyApi() {
        const url = `${process.env.VUE_APP_PASSPORT_URI}/public/request_public_key`;
        return container.RnDHttp.get({
            url,
        });
    }
    /**
     * 发送短信验证码
     */
    static postMobileVerifyCodeApi(data) {
        const url = `${process.env.VUE_APP_PASSPORT_URI}/public/mobile-number/verify-code`;
        return container.RnDHttp.postForm({
            url,
            data: qs.stringify(data),
        });
    }
    /**
     * 领取活动奖励
     */
    static postReceiveAwardApi(activeType) {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/activity/receive-award?active_type=${activeType}`;
        return container.RnDHttp.post({
            url,
        });
    }
    static postAccountInfoHubspot(isOldUser) {
        const url = `${process.env.VUE_APP_ACCOUNT_SERVICE_URI}/user-service/account-info`;
        const channelParams = isOldUser ? { channel_code: getVar('channel_code') || CHANNEL_SOURCE } : {};
        return container.AccountHttp.put({
            url,
            method: 'put',
            data: {
                ...channelParams,
                sales_lead_send: true,
            },
        });
    }
    static getGlobalControl() {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/amounts/control`;
        return container.RnDHttp.get({
            url,
        }).then(res => {
            return res.data;
        });
    }
    /**
     * 获取用户版本权限
     */
    static getUserPackageType() {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/package-info`;
        return container.RnDHttp.get({
            url,
        }).then(res => {
            return res.data;
        });
    }
    /**
     * 保存已引导过的页面
     */
    static saveGuidedPage(page) {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/save-guide-alert/${page}`;
        return container.RnDHttp.post({
            url,
        }).then(res => {
            return res.data;
        });
    }
    /**
     * 引导界面埋点
     * @param isRD
     */
    static saveGuidedTrack(isRD) {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/tracking/guide?isRD=${!!isRD}`;
        return container.RnDHttp.get({
            url,
        }).then(res => {
            return res.data;
        });
    }
    static getSuggestTopic() {
        const url = `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/smart/suggest/company-topic`;
        return container.RnDHttp.get({
            url,
        }).then(res => {
            return res.data;
        });
    }
    static track(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/tracking/add`,
            data,
        }).then(res => {
            return res.data;
        });
    }
    /**
     *
     * @param queryData
     * @returns 换一个qid
     */
    static queryItemToShort(data) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/web-query/transfer-to-short`,
            data: data,
        }).then(res => {
            return res.data;
        });
    }
    /**
     * @param eureka 和 air入口切换
     */
    static setSelectedModule(module) {
        return container.RnDHttp.post({
            url: `${process.env.VUE_APP_EUREKA_SERVICE_URI}/eureka/save-selected-module/${module}`,
        }).then(res => {
            return res.data;
        });
    }
}
__decorate([
    MergeApi({ delay: 300 }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], CommonApis, "getSolutionData", null);
